<template>
<div class="in-report-manage">
    <component :is="views" @goEdit="goEdit"></component>
</div>
</template>

<script>
import sbList from '@/views/SBGL/Device/components/jzq/sbList';
import sbEdit from '@/views/SBGL/Device/components/jzq/sbEdit';
import chList from '@/views/SBGL/Device/components/jzq/children/childDeviceList';
import setDevice from '@/views/SBGL/Device/components/jzq/children/setDevice';
export default {
    data() {
        return {
            views: sbList
        }
    },
    methods: {
        goEdit(row) {
            console.log(row)
            this.$router.push({
                path: '/caseManage/caseindex',
                query: {
                    json: JSON.stringify(row)
                }
            })
        }
    },
    mounted() {
        if (this.$route.query && this.$route.query.state) {
            if (this.$route.query.state === 'jzqadd') {
                this.views = sbEdit
            }else if (this.$route.query.state === 'chlist') {
                this.views = chList
            }else if (this.$route.query.state === 'setdevice') {
                this.views = setDevice
            } else {
                this.views = sbList
            }

        } else {
            this.views = sbList
        }
    }
}
</script>
