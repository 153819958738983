<template>
<div
 class="main home">
    <div class="home-box">
        <h1>集中器管理</h1>
        <div class="home-content">
            <!-- 查询条件 -->
            <search-item :searchInfo.sync="info" @searchList="searchList"></search-item>
            <!-- 表格 -->
            <div class="table_container">
                <el-table size="mini" border :data="tableData" stripe v-loading="tableLoading" style="width: 100%" align='center'>
                    <!-- <el-table-column prop="deviceId" width="210" label="设备ID" align='center'>
                    </el-table-column> -->
                    <el-table-column prop="imei" width="210" label="IMEI" align='center'>
                    </el-table-column>
                    <el-table-column prop="imsi" width="230" label="IMSI" align='center'>
                    </el-table-column>
                    <el-table-column prop="mode" width="100" label="设备模式" align='center'>
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.mode == '1'">LORA</el-tag>
                            <el-tag v-if="scope.row.mode == '2'">移动NB</el-tag>
                            <el-tag v-if="scope.row.mode == '3'">电信NB</el-tag>
                            <el-tag v-if="scope.row.mode == '4'">全网通</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="status" width="100" label="在线状态" align='center'>
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.status == '0'" type="success">在线</el-tag>
                            <el-tag v-if="scope.row.status == '1'" type="danger">离线</el-tag>
                            <el-tag v-if="scope.row.status == '2'" type="warning">已贮存</el-tag>
                            <el-tag v-if="scope.row.status == '3'" type="danger">已报废</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="enprNo" width="230" label="所属水司" align='center'>
                    </el-table-column>
                    <el-table-column prop="createTime" label="注册时间" sortable align='center'>
                    </el-table-column>
                    <el-table-column prop="remark" label="备注" align='center'>
                    </el-table-column>
                    <el-table-column width="200px" fixed="right" label="操作" align='center'>
                        <template slot-scope='scope'>
                            <el-button type="text" size="mini" @click='setChindDevice(scope.row)'>设置子表</el-button>
                            <el-button type="text" size="mini" @click='goChildDetail(scope.row)'>查看子表</el-button>
                            <el-button type="text" size="mini" @click='del(scope.row)'>删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <pagination :paginationInfo="paginationInfo" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange"></pagination>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Pagination from "@/components/pagination";
import SearchItem from "./searchItem";
export default {
    data() {
        return {
            // 表格loading
            tableLoading: false,
            tableLoading1: false,
            tableData: [], //表格数据
            paginationInfo: {
                pageIndex: 1, // 当前位于哪页
                pageTotal: 8, //表格总页数
                pageSize: 10, //每页显示条数
                pageSizes: [5, 10, 15, 20], //每页显示个数选择器的选项设置
                layout: "total, sizes, prev, pager, next, jumper" // 翻页属性
            },
            pidList: [],
            yxqVisible: false,
            registerId: "",
            info: {
                deviceNo: "",
                imei: "",
                deviceMode: "",
                status: "",
                enterpriseId: "",
                tapStatus: "",
                electricity: "",
                batchNo: ""
            },
            commandVisible: false,
            command: '0',
            modifyEnprVisible: false,
            nowEnpr: '',
            nowDeviceId: '',
            newEnpr: '',
            selectedRow: '',
            enprLoading: false,
            commandLoading: false,
            reportVisible: false
        }
    },
    mounted() {
        let that = this
        this.getDeviceList(that.paginationInfo.pageIndex, that.paginationInfo.pageSize, that.info)
    },
    methods: {
        //  获取表格信息
        getDeviceList(currpage, pageSize, info) {
            console.log(info)
            this.tableLoading = true;
            let params = {
                pageNum: currpage,
                pageSize: pageSize,
                imei: this.info.imei
            }
            this.tableData = []
            this.$api.getJZQList(params).then(res => {
                // console.log('000', res.data.pageList)
                this.tableLoading = false;
                if (res && res.code == 0) {
                    this.paginationInfo.pageTotal = parseInt(res.data.total)
                    this.paginationInfo.pageIndex = res.data.pageNum
                    this.tableData = res.data.list
                }else {
                    this.$message.error(res.msg)
                }
            }).catch(error => {
                console.log(error)
                this.tableLoading = false;
            })
        },
        searchList(e) {
            console.log(e)
            this.info = e
            this.getDeviceList(1, this.paginationInfo.pageSize, e)
        },
        // 上下分页
        handleCurrentChange(val) {
            // console.log('上下分页',val)
            let that = this
            this.paginationInfo.pageIndex = val
            this.getDeviceList(that.paginationInfo.pageIndex, that.paginationInfo.pageSize, that.info)
        },
        // 每页显示多少条
        handleSizeChange(val) {
            // console.log('每页显示多少条',val)
            let that = this
            this.paginationInfo.pageSize = val
            this.getDeviceList(that.paginationInfo.pageIndex, that.paginationInfo.pageSize, that.info)
        },
        goChildDetail(e){
            console.log(e)
            sessionStorage.setItem('jzqinfo',JSON.stringify({imei: e.imei, enprNo: e.enprNo}))
            this.$router.push({
                path: '/jzqlist',
                query: {
                    state: 'chlist',
                    deviceId: e.deviceId
                }
            })
        },
        del(e) {
            console.log(e)
            let that = this
            this.$confirm('确定要删除该设备吗?删除后将无法恢复，需重新注册', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$api.deleteJZQ({
                    id: e.id
                }).then(res => {
                    if (res.code === 0) {
                        this.getDeviceList(that.paginationInfo.pageIndex, that.paginationInfo.pageSize, that.info)
                        this.$message({
                            type: 'success',
                            message: '删除成功'
                        });
                    } else {
                        this.$message({
                            type: 'error',
                            message: '删除失败'
                        });
                    }
                })
            }).catch(() => {});
        },
        setChindDevice(e){
            this.$router.push({
                path: '/jzqlist',
                query: {
                    state: 'setdevice',
                    deviceId: e.deviceId
                }
            })
        }
    },
    components: {
        Pagination,
        SearchItem
    }
}
</script>

<style>
/* 标题 */
.menu .title1 img {
    top: 22px;
}

.menu .title1 h3 {
    font-size: 20px;
    margin-top: -14px;
}

/* 分页 */
.el-pagination button,
.el-pagination span:not([class*=suffix]) {
    padding: 0 10px;
}

.home .block-title {
    width: 100%;
    padding: 0 0 28px 20px;
    display: flex;
    align-items: center;
}

.home .block-title h3 {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #2d3039;
}

.home .a-text {
    text-decoration: underline;
    color: #1e61ce;
}

.home .block-title .btn {
    width: 180px;
    height: 35px;
    background-color: #1e61ce;
    border-radius: 20px;
    margin-left: 20px;
    font-weight: normal;
    font-stretch: normal;
    color: #ffffff;
    padding: 0;
}

.home .btn span {
    font-size: 16px !important;
    color: #fff !important;
}

.home .block-title span {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #63717A;
}

.home .top {
    width: 100%;
    padding: 0 0 10px 0;
    border-radius: 10px;
    background: #fefefe;
}

.home .top .title {
    width: 100%;
    height: 90px;
    line-height: 90px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    font-family: PingFangSC-Semibold;
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #2d3039;
    border-bottom: 1px solid #f2f3f5;
}

.home .el-col {
    border-radius: 10px;
}

.home .grid-content {
    border-radius: 10px;
    /* min-height: 36px; */
}

.home .leftInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.home .leftInfo .headPic {
    width: 165px;
    height: 165px;
    border-radius: 100%;
    border: 8px solid #fff;
    position: relative;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    box-shadow: 0px 12px 30px 0px rgba(6, 37, 117, 0.12);
}

.home .leftInfo .headPic img {
    width: 100%;

}

.home .rightInfo {
    margin-top: 10px;
    padding-right: 10px;
}

.home .rightInfo .name-text {

    margin-bottom: 20px;
    font-family: PingFangSC-Semibold;
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #2d3039;
}

.home .rightInfo .info-text img {
    width: 26px;
    height: 26px;
    margin-right: 20px;
}

.home .rightInfo .info-text {
    margin-bottom: 20px;
    font-family: PingFangSC-Medium;
    font-size: 18px;
    letter-spacing: 0px;
    color: #63717a;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    text-align: left
}

.home .rightInfo .info-text h1 {
    font-size: 20px;
    font-weight: 600;
    font-family: PingFangSC-Medium;
    color: #2d3039;
    margin-left: 10px;
    display: block;
    font-size: 90.5%;
}

.home .middle {
    width: 100%;
    border-radius: 10px;
}

.home .middle .grid-content {
    background: #fff
}

.home .title1 {
    width: 100%;
    display: flex;
    padding: 0 20px 20px 20px;
    align-items: center;
    margin-top: -10px;
    border-bottom: 1px solid #f2f3f5;
}

.home .title1 h3 {
    font-size: 20px;
    margin-top: -10px;
}

.home .title1 img {
    position: relative;
    top: 18px;
    margin-right: 10px;
}

.home .ajs-Title {
    margin-left: 20px;
}

.home .ajs {
    margin-left: 20px;
    color: #0033cf;
}

.home .left-block {
    height: 200px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px;
    /* border-right: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8; */
}

.home .right-block {
    height: 200px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px;
    border-bottom: 1px solid #d8d8d8;
}

.home .main-block {
    height: 200px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px;
    border-bottom: 1px solid #d8d8d8;
}

/*案件办理天数 标签统计 hmm 090403*/
.home .labelModule {
    background: #F2F5FA;
    border: 1px solid #D6E0F0;
    border-radius: 10px;
    display: inline-block;
    width: 100%;
    padding: 20px;
}

.home .labelModule:first-child {
    margin-bottom: 20px;
}

.home .labelModule ul li {
    width: 100%;
    font-size: 16px;
    color: #63717A;
    text-align: center
}

.home .labelModule ul li:first-child {
    margin-bottom: 10px;
}

.home .labelModule ul li font {
    font-size: 41px;
    color: #2D3039;
}

/*公共部分 hmm*/
.p20 {
    padding: 20px;
}

.pb30 {
    padding-bottom: 30px;
}

/*办案类型 数据超出 显示样式 hmm 090404*/

.widthBig .labelModule {
    width: 49.2%;
}

.widthBig .labelModule:first-child {
    margin-bottom: 30px;
    margin-right: 20px;
}

.widthSmall {
    float: right;
}

.openstate {
    color: #8CC663;
}

.stopstate {
    color: #e92d0c;
}
</style>
