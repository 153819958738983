<template>
<div id="registDevice" class="main home">
    <div class="home-box">
        <h1>集中器注册设备</h1>
        <div class="home-content">
            <el-form ref="form" :model="form" label-width="90px">
                <el-form-item label="IMEI" required>
                    <el-input class="inputItem" placeholder="请输入IMEI" v-model="form.imei"></el-input>
                    <el-tooltip class="item" content="IMEI:移动设备国际识别码，用于设备鉴权。填写内容需保持与设备内置信息一致。" placement="top-start"><img class="what" :src="require('@/assets/icon/other/what.png')" /></el-tooltip>
                </el-form-item>
                <el-form-item label="IMSI" required>
                    <el-input class="inputItem" placeholder="请输入IMSI" v-model="form.imsi"></el-input>
                    <el-tooltip class="item" content="国际移动用户识别码，用于区别移动用户的有效信息。填写需保持与SIM卡中信息一致。" placement="top-start"><img class="what" :src="require('@/assets/icon/other/what.png')" /></el-tooltip>
                </el-form-item>
                <el-form-item label="水司选择" required>
                    <el-select v-model="form.newEnpr" filterable clearable placeholder="请选择水司">
                        <el-option v-for="item in arrEnpr" :key="item.id" :label="item.enprName + ' - ' + item.enprNo" :value="item.enprNo">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="设备描述">
                    <el-input type="textarea" placeholder="请输入设备描述" class="inputItem" v-model="form.deviceDesc"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" :loading="submitLoading" @click="onSubmit">注册</el-button>
                    <el-button @click="back">返回</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            form: {
                imei: '',
                imsi: '',
                newEnpr: '',
                deviceDesc: ''
            },
            arrEnpr: [],
            operatorValue: '',
            operatorList: [{
                    value: '0',
                    label: '移动'
                },
                {
                    value: '1',
                    label: '电信'
                }
            ],
            deviceTypeValue: '',
            deviceTypeList: [],
            submitLoading: false
        }
    },
    mounted() {
        // this.getAllDevieType()
        this.getAllEnpr()
    },
    beforeDestroy() {

    },
    methods: {
        //获取所有水司
        getAllEnpr() {
            this.$api.getAllEnpr({}).then(res => {
                if (res.code === 0) {
                    this.arrEnpr = res.data
                }
            })
        },
        getAllDevieType() {
            this.$api.getAllDeviceType({}).then(res => {
                if (res.code === 0) {
                    this.deviceTypeList = res.data
                }
            })
        },
        onSubmit() {
            if (this.form.imei.toString().trim() == "") {
                this.$message.warning('请输入设备IMEI号码')
                return
            }
            if (this.form.imsi.toString().trim() == "") {
                this.$message.warning('请输入设备IMSI号码')
                return
            }
            if (this.form.newEnpr.toString().trim() == "") {
                this.$message.warning('请选择水司')
                return
            }
            let that = this
            let params = {
                imei: this.form.imei,
                imsi: this.form.imsi,
                enprNo: this.form.newEnpr,
                remark: this.form.deviceDesc
            }
            this.submitLoading = true
            this.$api.addJZQDevice(params).then(res => {
                this.submitLoading = false
                if (res.code === 0) {
                    this.form = {
                            imei: '',
                            imsi: '',
                            newEnpr: '',
                            deviceDesc: ''
                        },
                        this.$message.success('注册成功')
                    setTimeout(() => {
                        that.$router.push({
                            path: '/jzqList'
                        })
                    }, 2000);
                } else {
                    this.$message.error('注册失败')
                }
            })
        },
        back() {
            history.back()
        }
    }
}
</script>

<style lang="scss">
#registDevice {
    .inputItem {
        width: 340px;
    }

    .el-input__inner {
        width: 340px;
    }

    .home-content {
        padding-top: 20px;
        padding-bottom: 20px;

        .what {
            width: 14px;
            margin-left: 10px;
            cursor: pointer;
        }
    }

    .el-form-item__content {

        align-items: center;
    }
}
</style>
