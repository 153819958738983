<template>
<div class="search_container searchArea">
    <el-form :inline="true" :model='searchInfo' ref="searchInfo" :rules="rules" :label-position="labelPosition" class="demo-form-inline search-form">
        <div class="search_form_item">
            <el-form-item prop="name1" label="表号">
                <el-input v-model="searchInfo.deviceNo" @change="deviceNoChange" clearable></el-input>
            </el-form-item>
            <el-form-item prop="name4" label="所属水司">
                <el-select v-model="searchInfo.enprNo" clearable filterable placeholder="请选择">
                    <el-option v-for="item in arrEnpr" :key="item.id" :label="item.enprName + ' - ' + item.enprNo" :value="item.enprNo" @change="enterpriseIdChange">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item class="search-btn">
                <el-button type="primary" @click="searchList()">查询</el-button>
            </el-form-item>
        </div>
    </el-form>
</div>
</template>

<script>
import {
    mapGetters
} from 'vuex'

export default {
    name: 'searchItem',
    props: {
        searchInfo: {
            type: Object,
            required: true
        }

    },
    data() {
        return {
            labelPosition: 'left',
            rules: {},
            arrEnpr: [],
        }
    },
    computed: {},
    created() {},
    mounted() {
        this.getAllEnprs()
    },
    methods: {
        // 查询
        searchList() {
            this.$emit('searchList', this.searchInfo)
        },
        deviceNoChange(e) {
            this.searchInfo.deviceNo = e
            this.$emit('update:searchInfo', this.searchInfo)
        },
        getAllEnprs() {
            this.$api.getAllEnpr({}).then(res => {
                if (res.code === 0) {
                    this.arrEnpr = res.data
                }
            })
        },
        enterpriseIdChange(e) {
            console.log(e)
            this.searchInfo.enprNo = e
            this.$emit('update:searchInfo', this.searchInfo)
        }
    }
}
</script>

<style>
.search_container {
    padding: 40px 0 18px;
    /* border-bottom: 1px solid #eff1f4; */
}
</style>
