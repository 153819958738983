<template>
<div class="search_container searchArea">
    <el-form :inline="true" :model='searchInfo' ref="searchInfo" :rules="rules" :label-position="labelPosition" class="demo-form-inline search-form">
        <div class="search_form_item">
            <el-form-item prop="name1" label="IMEI号码">
                <el-input v-model="searchInfo.imei" @change="imeiChange" clearable></el-input>
            </el-form-item>
            <el-form-item class="search-btn">
                <el-button type="primary" @click="searchList()">查询</el-button>
                <el-button type="primary" @click="addDevice()">注册新设备</el-button>
                <!-- <el-button type="primary" @click="getChildDevice()">查询子设备信息</el-button> -->
            </el-form-item>
        </div>
    </el-form>
</div>
</template>

<script>
import {
    mapGetters
} from 'vuex'

export default {
    name: 'searchItem',
    props: {
        searchInfo: {
            type: Object,
            required: true
        }

    },
    data() {
        return {
            labelPosition: 'left',
            rules: {},
            deviceModeList: [{
                    value: '2',
                    label: '移动'
                },
                {
                    value: '3',
                    label: '电信'
                }
            ],
            stateData: [{
                    value: '0',
                    label: '在线'
                },
                {
                    value: '1',
                    label: '离线'
                }
            ],
            arrEnpr: [],
            tapStausData: [{
                    value: '0',
                    label: '关'
                },
                {
                    value: '1',
                    label: '开'
                },
                {
                    value: '2',
                    label: '未知'
                }
            ],
        }
    },
    computed: {},
    created() {},
    mounted() {
        this.getAllEnprs()
    },
    methods: {
        getAllEnprs() {
            this.$api.getAllEnpr({}).then(res => {
                if (res.code === 0) {
                    this.arrEnpr = res.data
                }
            })
        },
        // 查询
        searchList() {
            this.$emit('searchList', this.searchInfo)
        },
        addDevice() {
            this.$router.push({
                path: '/jzqlist',
                query: {
                    state: 'jzqadd'
                }
            })
        },
        imeiChange(e) {
            this.searchInfo.imei = e
            this.$emit('update:searchInfo', this.searchInfo)
        },
        getChildDevice(){
            this.$router.push({
                path: '/jzqlist',
                query: {
                    state: 'chlist'
                }
            })
        }
        
    }
}
</script>

<style>
.search_container {
    padding: 40px 0 18px;
    /* border-bottom: 1px solid #eff1f4; */
}
</style>
