<template>
<div id="setDevice" class="main home" style="padding-bottom:20px;">
    <div class="home-box" style="padding-bottom:20px;">
        <h1>设置子表</h1>
        <div class="table_container" style="margin-top:20px;">
            <el-table size="mini" border :data="tableData" stripe style="width: 100%" align='center'>
                <!-- <el-table-column prop="deviceId" width="210" label="设备ID" align='center'>
                    </el-table-column> -->
                <el-table-column prop="imei1" label="包号1[线号1]" align='center'>
                    <template slot-scope="scope">
                        <el-form label-width="80px">
                            <el-form-item :label="'表' + (scope.$index + 1)">
                                <el-input clearable v-model="scope.row.imei1"></el-input>
                            </el-form-item>
                        </el-form>
                    </template>
                </el-table-column>
                <el-table-column prop="imei2" label="包号2[线号1]" align='center'>
                    <template slot-scope="scope">
                        <el-form label-width="80px">
                            <el-form-item :label="'表' + (scope.$index + 1)">
                                <el-input clearable v-model="scope.row.imei2"></el-input>
                            </el-form-item>
                        </el-form>
                    </template>
                </el-table-column>
                <el-table-column prop="imei3" label="包号3[线号2]" align='center'>
                    <template slot-scope="scope">
                        <el-form label-width="80px">
                            <el-form-item :label="'表' + (scope.$index + 1)">
                                <el-input clearable v-model="scope.row.imei3"></el-input>
                            </el-form-item>
                        </el-form>
                    </template>
                </el-table-column>
                <el-table-column prop="imei4" label="包号4[线号2]" align='center'>
                    <template slot-scope="scope">
                        <el-form label-width="80px">
                            <el-form-item :label="'表' + (scope.$index + 1)">
                                <el-input clearable v-model="scope.row.imei4"></el-input>
                            </el-form-item>
                        </el-form>
                    </template>
                </el-table-column>
            </el-table>
            <br />
            <div style="width:100%; height:80px; display:flex; justify-content: center; align-items: center;">
                <el-button type="primary" :loading="submitLoading" @click="onSubmit">保存</el-button>
                <el-button @click="back">返回</el-button>
            </div>
        </div>
    </div>

</div>
</template>

<script>
export default {
    data() {
        return {
            tableData: [{
                    imei1: '000000000000',
                    imei2: '000000000000',
                    imei3: '000000000000',
                    imei4: '000000000000',
                },
                {
                    imei1: '000000000000',
                    imei2: '000000000000',
                    imei3: '000000000000',
                    imei4: '000000000000',
                }, {
                    imei1: '000000000000',
                    imei2: '000000000000',
                    imei3: '000000000000',
                    imei4: '000000000000',
                }, {
                    imei1: '000000000000',
                    imei2: '000000000000',
                    imei3: '000000000000',
                    imei4: '000000000000',
                }, {
                    imei1: '000000000000',
                    imei2: '000000000000',
                    imei3: '000000000000',
                    imei4: '000000000000',
                }, {
                    imei1: '000000000000',
                    imei2: '000000000000',
                    imei3: '000000000000',
                    imei4: '000000000000',
                }, {
                    imei1: '000000000000',
                    imei2: '000000000000',
                    imei3: '000000000000',
                    imei4: '000000000000',
                }, {
                    imei1: '000000000000',
                    imei2: '000000000000',
                    imei3: '000000000000',
                    imei4: '000000000000',
                }, {
                    imei1: '000000000000',
                    imei2: '000000000000',
                    imei3: '000000000000',
                    imei4: '000000000000',
                }, {
                    imei1: '000000000000',
                    imei2: '000000000000',
                    imei3: '000000000000',
                    imei4: '000000000000',
                }, {
                    imei1: '000000000000',
                    imei2: '000000000000',
                    imei3: '000000000000',
                    imei4: '000000000000',
                }, {
                    imei1: '000000000000',
                    imei2: '000000000000',
                    imei3: '000000000000',
                    imei4: '000000000000',
                }, {
                    imei1: '000000000000',
                    imei2: '000000000000',
                    imei3: '000000000000',
                    imei4: '000000000000',
                }, {
                    imei1: '000000000000',
                    imei2: '000000000000',
                    imei3: '000000000000',
                    imei4: '000000000000',
                }, {
                    imei1: '000000000000',
                    imei2: '000000000000',
                    imei3: '000000000000',
                    imei4: '000000000000',
                }, {
                    imei1: '000000000000',
                    imei2: '000000000000',
                    imei3: '000000000000',
                    imei4: '000000000000',
                }
            ],
            submitLoading: false,
            deviceid: ''
        }
    },
    mounted() {
        this.getDeviceList(this.$route.query.deviceId)
        this.deviceid = this.$route.query.deviceId
    },
    methods: {
        getDeviceList(deviceId) {
            this.$api.getJZQDeviceList({
                deviceId
            }).then(res => {
                if (res.code == 0) {
                    if (res.data.length > 0) {
                        res.data.map(item => {
                            if(item.tn > 16){
                                this.tableData[item.tn % 16 - 1]["imei" + item.packageNo] = item.imei
                            }else {
                                this.tableData[item.tn - 1]["imei" + item.packageNo] = item.imei
                            }
                            
                            
                        })
                    }
                    console.log(this.tableData)
                }
            })
        },
        onSubmit() {
            this.submitLoading = true
            let params = {
                deviceId: this.deviceid,
                deviceList: []
            }
            for (let i = 0; i < this.tableData.length; i++) {
                if (this.tableData[i].imei1 == "") {
                    this.$message.warning(`请输入包号1下的第${i + 1}块设备表号`)
                    this.submitLoading = false
                    return
                }
                if (this.tableData[i].imei2 == "") {
                    this.$message.warning(`请输入包号2下的第${i + 1}块设备表号`)
                    this.submitLoading = false
                    return
                }
                if (this.tableData[i].imei3 == "") {
                    this.$message.warning(`请输入包号3下的第${i + 1}块设备表号`)
                    this.submitLoading = false
                    return
                }
                if (this.tableData[i].imei4 == "") {
                    this.$message.warning(`请输入包号4下的第${i + 1}块设备表号`)
                    this.submitLoading = false
                    return
                }
                params.deviceList.push({
                    packageNo: 1,
                    lineNo: 1,
                    tn: i + 1,
                    imei: this.tableData[i].imei1
                })
                params.deviceList.push({
                    packageNo: 2,
                    lineNo: 1,
                    tn: i + 17,
                    imei: this.tableData[i].imei2
                })
                params.deviceList.push({
                    packageNo: 3,
                    lineNo: 2,
                    tn: i + 1,
                    imei: this.tableData[i].imei3
                })
                params.deviceList.push({
                    packageNo: 4,
                    lineNo: 2,
                    tn: i + 17,
                    imei: this.tableData[i].imei4
                })
            }

            console.log(params)
            this.$api.relationDevice(params).then(res => {
                this.submitLoading = false
                if(res.code == 0){
                    this.$message.success('提交成功，请等待水表主动上报后生效')
                    history.back()
                }else {
                    this.$message.error('提交失败')
                }
                console.log(res)
            })
        },
        back() {
            history.back();
        }
    }
}
</script>

<style lang="scss">
#setDevice {
    .el-input__inner {
        height: 32px;
        width: 220px;
    }

    .el-form-item {
        margin-bottom: 0;
    }
}
</style>
